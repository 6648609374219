.header {
    margin: 20px;
}
.header-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
    p {
        display: flex;
        align-items: center;
        & .label {
            margin-right: 24px;
            font-weight: bold;
        }
    }
}
.confirm-icon-button {
    border: 1px solid #CACACB;
    border-radius: 25px;
    width: 36px;
    height: 36px;
}