.tristate-checkbox {
    user-select: none;
    padding: 0;
    box-sizing: border-box;
}

.tristate-checkbox input[type="checkbox"] {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

.tristate-checkbox input[type="checkbox"] + div {
    border: 2px solid #8D9AA9;
    height: 1.5em;
    width: 1.5em;
    box-sizing: border-box;
    border-radius: 2px;
    position: relative;
}

.tristate-checkbox input[type="checkbox"]:focus + div,
.tristate-checkbox input[type="checkbox"]:active + div {
    outline: 2px dashed #8D9AA9;
    outline-offset: 2px;
}

.tristate-checkbox input[type="checkbox"]:checked + div {
    border-color: black;
    transition: border-color 107ms cubic-bezier(0.65, 0.25, 0.56, 0.96);

    .tristate-check {
        opacity: 1;
        transition: opacity 107ms cubic-bezier(0.65, 0.25, 0.56, 0.96);

        polyline {
            animation: dash-check 107ms cubic-bezier(0.65, 0.25, 0.56, 0.96) forwards;
        }
    }
}

.tristate-checkbox input[type="checkbox"]:indeterminate + div::after {
    content: "";
    height: 4px;
    width: 60%;
    left: 20%;
    top: calc(50% - 2px);
    position: absolute;
    background: #8D9AA9;
    border-radius: 1px;
}

.tristate-checkbox input[type="checkbox"]:disabled ~ div {
    color: #8D9AA9;
    cursor: not-allowed;
}

.tristate-checkbox input[type="checkbox"]:enabled ~ div {
    cursor: default;
}

.tristate-check {
    height: 100%;
    width: 100%;
    transform: scale(1);
    color: black;
    opacity: 0;

    polyline {
        fill: none;
        transform-origin: 50% 50%;
        stroke-width: 5px;
        stroke-dasharray: 22.771367900227325;
        stroke: currentcolor;
    }
}

@supports (display: grid) {
    .tristate-check {
        polyline {
            stroke-dashoffset: 22.771367900227325;
        }
    }
}

@keyframes dash-check {
    to {
        stroke-dashoffset: 0;
    }
}