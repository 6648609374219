.breadcrumbs {
  margin-bottom: 16px;
  font-size: 30px;
  .breadcrumb {
    a {
      text-decoration: none;
      color: #0070F3;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}