.toolbar {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  li {
    margin-right: 20px;
    text-decoration: none;

    &:last-child {
      margin-right: 0;
    }

    a, p {
      color: white;
      font-size: 16px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.table-header {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}