.m_icon svg {
  width: 22px;
  height: 22px;
}

.l_icon svg {
  width: 44px;
  height: 44px;
}

[class$="_icon"] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
